//引入axios：
import axios from 'axios'

//引入axios官方封装好的针对config参数做的类型注解、针对Response做的类型注解：
import type { AxiosRequestConfig, AxiosResponse } from 'axios'

//引入store状态管理，目的是为了获取其中存储的token：
import store from '@/store'
//引入store类型：
import type { StateAll } from '@/store'
//后续会将store的state断言成StateAll类型：(store.state as StateAll)

//引入ElMessage：
import { ElMessage } from 'element-plus'

// 动态设置 baseURL
const getBaseURL = () => {
  const hostname = window.location.hostname;
  if (hostname === 'liangmingyi.com') {
    return 'https://liangmingyi.com';
  } else if (hostname === 'www.liangmingyi.com') {
    return 'https://www.liangmingyi.com';
  }
  // 可以根据需要添加更多的域名和对应的 baseURL
  return 'http://localhost:8080'; // 默认的 baseURL
};

const instance = axios.create({
  //浏览器访问会拼接基础URL，访问线上接口只需要把线上接口地址（在md里面有）替换本地地址即可：
  // baseURL: 'http://localhost:8080',
  baseURL: getBaseURL(),
  // baseURL: 'https://www.kirinwordmindmap.com',
  // baseURL: 'https://www.liangmingyi.com',
  // baseURL: 'http://101.43.161.36:8000',
  // baseURL: 'http://49.232.151.101:8000',
  //  baseURL: 'http://127.0.0.1:5500',



  //超时时间：
  timeout: 5000
});

//请求拦截器：
instance.interceptors.request.use(function (config) {
  // config.header可能为undefined，可以使用非空断言，或者做一下类型保护，这里做类型保护：
  if (config.headers) {
    //headerUserToken直接就是接口规定的参数名，等号后面就是参数值：
    config.headers.headerUserToken = (store.state as StateAll).users.token;
  }  //下面的内容都是默认的
  return config;
}, function (error) {
  return Promise.reject(error);
});

//响应拦截器：
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response && error.response.data.status === 501) {
    // window.location.replace('/login');
    ElMessage.warning('请登录后再试试吧~')
    setTimeout(() => {
      window.location.replace('/login');
    }, 1000)
    store.commit('users/clearToken');  //调用清空token方法
    return Promise.reject(error);
  } else if (error.response && error.response.data.status === 5621) {
    ElMessage.warning('操作太频繁啦，手慢一点~')
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
});







//因为对Data数据格式不确定（就是有很多类型，不明确具体是哪一种），所以用索引签名做类型注解：
interface Data {
  [index: string]: unknown
}

//对常用接口实例做统一处理的类型注解：
interface Http {
  get: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
  post: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
  put: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
  patch: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
  delete: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
}

//对常用接口实例，调用实例来做统一处理：
const http: Http = {
  get(url, data, config) {
    return instance.get(url, {
      params: data,
      ...config   //...是拓展运算符，让config与前面两类参数合并
    })
  },
  post(url, data, config) {
    return instance.post(url, data, {
      params: data,
      ...config
    })
  },
  put(url, data, config) {
    return instance.put(url, data, config)
  },
  patch(url, data, config) {
    return instance.patch(url, data, config)
  },
  delete(url, data, config) {
    return instance.delete(url, {
      data,
      ...config  //...是拓展运算符，让config与前面两类参数合并
    })
  }
}

//对外暴露http这个接口
export default http;